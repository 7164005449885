// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-hire-me-js": () => import("/Users/olivera/projects/heyitsolivia/src/pages/hire-me.js" /* webpackChunkName: "component---src-pages-hire-me-js" */),
  "component---src-pages-index-js": () => import("/Users/olivera/projects/heyitsolivia/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("/Users/olivera/projects/heyitsolivia/src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-selected-work-js": () => import("/Users/olivera/projects/heyitsolivia/src/pages/selected-work.js" /* webpackChunkName: "component---src-pages-selected-work-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/olivera/projects/heyitsolivia/.cache/data.json")

